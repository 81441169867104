import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11')
];

export const server_loads = [];

export const dictionary = {
		"/": [~3],
		"/login": [~4],
		"/member/dashboard": [~5,[2]],
		"/member/invoices": [~6,[2]],
		"/member/invoices/[id]": [~7,[2]],
		"/member/service-providers": [~8,[2]],
		"/member/settings": [~9,[2]],
		"/member/settings/mail-readers/[id]": [~10,[2]],
		"/register": [~11]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';